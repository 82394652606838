export const environment = {
  theme: '#F9A825',
  production: true,
  name: 'staging',
  apiUrl: 'https://backend.staging.paymefy2.paymefy.com/graphql/',
  mapQuest: 'Iv2jb45oodNOetEP9ZkJlj0zRjtB21vg',
  GoogleMap: 'AIzaSyDMVXuDdx-5iE6gjdAgdxME5S-zmVzBSIo',
  appVersion: '1.4.0',
  apiKeyUnica: '9la3ZNkLZPagx6Rl4mrT',

  isMockEnabled: false,
  auth: {
    domain: 'dev-hci4tba0.eu.auth0.com',
    clientId: 'AhIquStu6MxyHNeKQm1OiaBnUM0J2xRa',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://paymefy.com',
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://backend.staging.paymefy2.paymefy.com/graphql/',
          tokenOptions: {
            authorizationParams: {
              audience: 'https://paymefy.com',
            },
          },
        },
      ],
    },
  },
};
